<template>
  <page-layout>
    <span slot="header-name">{{ $tf('counter_records') }}</span>
    <table-navigation slot="header-content-actions" :state="state"></table-navigation>
    <div slot="content" v-loading="state.loading">
      <el-table
        name="counter-records-table"
        class="counter-records-table"
        stripe
        :size="$vars.sizes.table || $vars.sizes.common"
        :data="state.items"
        row-key="id"
        :row-class-name="tableRowClassName"
        :span-method="arraySpanMethod"
      >
        <el-table-column prop="id" :label="$tf('id')" width="100">
          <template slot-scope="{ row }">
            <template v-if="row.error">{{ row.id }}<br />{{ row.error_msg }}</template>
            <template v-else>{{ row.id }}</template>
          </template>
        </el-table-column>

        <el-table-column prop="image" :label="$tf('image')" width="180">
          <template slot-scope="{ row }">
            <img
              class="image-thumbnail"
              :class="!row.fullframe ? 'image-thumbnail-correction' : ''"
              :src="row.thumbnail"
              @click="() => showImage(row)"
              width="160"
            />
          </template>
        </el-table-column>

        <el-table-column prop="faces_count" :label="$tf('faces')" width="60" align="center"></el-table-column>
        <el-table-column prop="silhouettes_count" :label="$tf('silhouettes')" width="90" align="center"></el-table-column>

        <el-table-column :label="$tf('distance')" :render-header="renderDistanceHeader">
          <template slot-scope="{ row }">
            <div v-if="!isEmpty(row.proximity_min)">
              {{ row.proximity_min.toFixed(2) + ' / ' + row.proximity_avg.toFixed(2) + ' / ' + row.proximity_max.toFixed(2) }}
            </div>
            <div v-else>- / - / -</div>
          </template>
        </el-table-column>

        <el-table-column :label="$tf('date')" width="160">
          <template slot-scope="{ row }">
            {{ $filters.formatDateTime($filters.isoStringToDate(row.created_date)) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <table-navigation slot="footer-content-navigation" :state="state"></table-navigation>
  </page-layout>
</template>

<script>
import TableNavigation from '@/components/tables/navigation.vue';
import PageLayout from '@/components/page/layout';
import _ from '@/apps/common/lodash';
import DistanceColumnHeader from '../counters/distance.column.header';

export default {
  components: {
    TableNavigation,
    PageLayout
  },
  name: 'page-counter-records',
  computed: {
    model() {
      return 'counter_records';
    },
    state() {
      return this.$store.state.counter_records;
    },
    stats() {
      return this.$store.state.stats;
    }
  },
  data() {
    return {
      selection: []
    };
  },
  mounted() {
    this.$store.dispatch(this.$store.state.counters.Action.Get);
  },
  beforeDestroy() {},
  methods: {
    isEmpty(v) {
      return v === null || v === undefined;
    },
    togglePlaying() {
      this.state.playing = !this.state.playing;
    },
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
    },
    showImage(v) {
      if (!v.fullframe) return;

      this.$store.dispatch('showImage', {
        src: v.fullframe,
        faces_bbox: this.getBBoxesFromArray(v.faces_bbox),
        silhouettes_bbox: this.getBBoxesFromArray(v.silhouettes_bbox)
      });
    },
    getCamera(item) {
      return this.$store.state.cameras.items.find((v) => v.id === item.camera) || { name: this.$tf('camera | not_found,,1') };
    },
    getCameraGroup(item) {
      let camera = this.getCamera(item);
      return this.$store.state.camera_groups.items.find((v) => v.id === camera.group) || { name: '' };
    },
    getBBoxesFromArray(v) {
      return (v || []).map((i) => this.$filters.polyArrayToShortRect(i));
    },
    tableRowClassName({ row }) {
      return row.error ? 'error' : '';
    },
    arraySpanMethod({ row }) {
      return row.error ? [1, 6] : [1, 1];
    },
    renderDistanceHeader(h, { column }) {
      return h(DistanceColumnHeader);
    }
  }
};
</script>

<style lang="stylus">

.event-reaction-content {
  margin-top: 1rem;
}

.filter--page-limit {
  display: inline-block;
  width: 5rem;
}

.tag-loading {
  cursor: none !important;
  opacity: .2;
}

.counters {
  justify-content: center;
}

.counter-records {
  .counter-records-table {
    .image-thumbnail-correction {
      cursor: default;
    }
  }
}
.error {
  &>td {
    background-color: rgba(197, 106, 106, .1) !important;
  }
}
</style>
