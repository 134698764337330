var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',[_c('span',{attrs:{"slot":"header-name"},slot:"header-name"},[_vm._v(_vm._s(_vm.$tf('counter_records')))]),_c('table-navigation',{attrs:{"slot":"header-content-actions","state":_vm.state},slot:"header-content-actions"}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.state.loading),expression:"state.loading"}],attrs:{"slot":"content"},slot:"content"},[_c('el-table',{staticClass:"counter-records-table",attrs:{"name":"counter-records-table","stripe":"","size":_vm.$vars.sizes.table || _vm.$vars.sizes.common,"data":_vm.state.items,"row-key":"id","row-class-name":_vm.tableRowClassName,"span-method":_vm.arraySpanMethod}},[_c('el-table-column',{attrs:{"prop":"id","label":_vm.$tf('id'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.error)?[_vm._v(_vm._s(row.id)),_c('br'),_vm._v(_vm._s(row.error_msg))]:[_vm._v(_vm._s(row.id))]]}}])}),_c('el-table-column',{attrs:{"prop":"image","label":_vm.$tf('image'),"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{staticClass:"image-thumbnail",class:!row.fullframe ? 'image-thumbnail-correction' : '',attrs:{"src":row.thumbnail,"width":"160"},on:{"click":function () { return _vm.showImage(row); }}})]}}])}),_c('el-table-column',{attrs:{"prop":"faces_count","label":_vm.$tf('faces'),"width":"60","align":"center"}}),_c('el-table-column',{attrs:{"prop":"silhouettes_count","label":_vm.$tf('silhouettes'),"width":"90","align":"center"}}),_c('el-table-column',{attrs:{"label":_vm.$tf('distance'),"render-header":_vm.renderDistanceHeader},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!_vm.isEmpty(row.proximity_min))?_c('div',[_vm._v(" "+_vm._s(row.proximity_min.toFixed(2) + ' / ' + row.proximity_avg.toFixed(2) + ' / ' + row.proximity_max.toFixed(2))+" ")]):_c('div',[_vm._v("- / - / -")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$tf('date'),"width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$filters.formatDateTime(_vm.$filters.isoStringToDate(row.created_date)))+" ")]}}])})],1)],1),_c('table-navigation',{attrs:{"slot":"footer-content-navigation","state":_vm.state},slot:"footer-content-navigation"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }